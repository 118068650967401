import React from 'react';

// Components
import {
  Layout,
  ServiceCard,
  Container,
  Statement,
  ServiceGroup,
} from '../../components';

// Data
import data from '../../data/pages/services.json';
import services from '../../data/services.json';

const Services = () => {
  const content = data.content;
  return (
    <Layout pageContext={data}>
      <Container offsetHero>
        {/* Iterate through service groups */}
        {Object.entries(services.groups).map(([key, value]) => (
          <ServiceGroup key={key} title={value.title} scrollOnMobile>
            {Object.values(services.data)
              .filter((service) => service.group === key)
              .map((service) => (
                <ServiceCard
                  key={service.path}
                  title={service.title}
                  text={service.blurb}
                  imagePath={service.imagePath}
                  cta={{ src: service.path, label: 'Learn More' }}
                />
              ))}
          </ServiceGroup>
        ))}

        {/* CTA Statement*/}
        <Statement
          title={content.statement.title}
          cta={content.statement.cta}
          styleType="cta">
          <p>{content.statement.text}</p>
        </Statement>
      </Container>
    </Layout>
  );
};

export default Services;
